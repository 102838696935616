import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100%;
  background: white;
  padding: 40px;

  a {
    color: black;

    :hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

export const FooterLinksWrapper = styled.div`
  height: 100%;
  padding-bottom: 50px;
  font-family: Roboto, Helvetica, sans-serif;

  > div {
    width: max-content;
    margin-bottom: 50px;
  }

  :not(li) > a {
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;
    width: 100%;
    display: block;
    margin-right: 60px;
    margin-top: 10px;

    @media ${({ theme }) => theme.media.tablet} {
      display: unset;
    }
  }

  ul {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    grid-column-gap: 20px;

    @media ${({ theme }) => theme.media.tablet} {
      grid-template-columns: repeat(5, 140px);
    }
  }
`;

export const AdditionalLinksWrapper = styled.ul`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
  font-family: Roboto, Helvetica, sans-serif;
  list-style: none;

  @media ${({ theme }) => theme.media.tablet} {
    text-align: right;
    display: block;
  }

  a {
    padding: 5px 0;
    margin-top: 10px;

    @media ${({ theme }) => theme.media.tablet} {
      margin: 0 20px 0 0;
      padding: 0;
    }
  }

  a:last-of-type {
    margin: 0;
  }
`;

export const AdditionalLink = styled.li`
  padding: 7px 0;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.lightGray};
  padding-top: 30px;
  font-family: Roboto, Helvetica, sans-serif;

  a {
    padding-right: 0;
  }

  img {
    height: 43px;
    width: auto;
  }

  span {
    margin-top: 10px;
  }
`;

export const FooterTitle = styled.p`
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 700;
  margin-top: 0;
`;
