import Link from 'next/link';
import type { ReactElement } from 'react';

import {
  AdditionalLink,
  AdditionalLinksWrapper,
  FooterLinksWrapper,
  FooterTitle,
  FooterWrapper,
  Info,
} from './Footer.styled';
import type { FooterLink, FooterNestedItem, FooterProps } from './Footer.types';
import type { LinkItemProps } from './Footer.types';

const LinkItem = ({ footerElement }: LinkItemProps) => {
  switch (true) {
    case !!(footerElement as FooterNestedItem).links:
      return (
        <div>
          <FooterTitle>{footerElement.title}</FooterTitle>
          <ul>
            {(footerElement as FooterNestedItem).links.map(item => (
              <li key={item.title}>
                <Link href={item.link}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      );
    default:
      return (
        <Link href={(footerElement as FooterLink).link}>
          {footerElement.title}
        </Link>
      );
  }
};

export const Footer = ({
  footerLinks,
  additionalLinks,
}: FooterProps): ReactElement => {
  if (!footerLinks) return null;

  return (
    <FooterWrapper>
      <FooterLinksWrapper>
        {footerLinks.map(link => (
          <LinkItem key={`footer-${link.title}`} footerElement={link} />
        ))}
      </FooterLinksWrapper>
      <Info>
        <Link href="/">
          <img
            alt="Logo FoxParadox. Mały narysowany lisek koloru czarnego obok napisu FoxParadox."
            src="/static/images/foxparadox-logo.svg"
            height={43}
            width={215}
          />
        </Link>
        <span>&copy; {new Date().getFullYear()} Fox Paradox</span>
      </Info>
      <AdditionalLinksWrapper>
        {additionalLinks.map(({ link, title }) => (
          <AdditionalLink key={`footer-${title}`}>
            <Link href={link}>{title}</Link>
          </AdditionalLink>
        ))}
      </AdditionalLinksWrapper>
    </FooterWrapper>
  );
};
